import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    	<Seo title="404 Not found" />

      <section id="banner" className="notfound">
      <div className="titlebox bgTitle height150">
        <h1>404 Not Found</h1>
      </div>
      </section>

		  <div id="main" className="404">
			  <section className="index-section ">
        <div className="content">
            <div className="centering-container">
              <h2>お探しのページは<br className="isShown"/>見つかりませんでした。</h2>
              <p>申し訳ありません。お探しのページが見つかりませんでした。<br className="isShown"/>ページ下部のサイトマップをご確認ください。</p>
            </div>
          </div>
        </section>
      </div>
  </Layout>
)

export default NotFoundPage
